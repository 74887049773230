var render = function () {
  var _vm$fundManagerData$a, _vm$fundManagerData$a2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "presentation",
    class: {
      'presentation--dashboard': _vm.dashboard
    }
  }, [_c('div', {
    staticClass: "image"
  }, [_vm.fundManagerData.logo ? _c('img', {
    attrs: {
      "src": _vm.fundManagerData.logo
    }
  }) : _c('div', {
    staticClass: "char"
  }, [_vm._v(" " + _vm._s(_vm.nameSymbol) + " ")])]), _c('div', {
    staticClass: "data"
  }, [_c('div', {
    staticClass: "data__title"
  }, [_vm._v(" " + _vm._s(_vm.fundManagerData.name) + " ")]), _c('div', {
    staticClass: "data__column "
  }, [_vm._v(" " + _vm._s((_vm$fundManagerData$a = _vm.fundManagerData.address) === null || _vm$fundManagerData$a === void 0 ? void 0 : _vm$fundManagerData$a.city) + ", " + _vm._s((_vm$fundManagerData$a2 = _vm.fundManagerData.address) === null || _vm$fundManagerData$a2 === void 0 ? void 0 : _vm$fundManagerData$a2.country) + " ")])]), _vm.fundManagerData.description || _vm.fundManagerData.www ? _c('div', {
    staticClass: "description"
  }, [_vm.fundManagerData.description ? [_c('div', {
    staticClass: "description__text"
  }, [_vm._v(" " + _vm._s(_vm.fundManagerData.description) + " ")])] : _vm._e(), _vm.dashboard && _vm.fundManagerData.www ? _c('a', {
    class: ['description__link', !_vm.fundManagerData.description ? 'reset' : ''],
    attrs: {
      "href": _vm.href(_vm.fundManagerData.www),
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icons/www.svg')
    }
  }), _vm._v(" " + _vm._s(_vm.fundManagerData.www) + " ")]) : _vm._e()], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }