





































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {FundManager} from "@/modules/genprox/models/User";
import SygniProgressBar from '@/components/layout/SygniProgressBar.vue';

@Component({
  components: {SygniRoundedButton, SygniCard, SygniProgressBar}
})
export default class FundManagerCard extends Vue {
  @Prop() fundManagerData!: FundManager;
  @Prop() dashboard!: boolean;
  @Prop() progressMax!: number;
  @Prop() progressVal!: number;

  get nameSymbol(): String {
    return this.fundManagerData.name?.slice(0, 1);
  }

  href(href: string) {
    const hrefProtocol = href.slice(0, 5);
    return !hrefProtocol.includes('https') ? `https://${href}` : href;
  }
}
