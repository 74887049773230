




import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniPagination from "@/components/table/SygniPagination.vue";
import { Prop, Watch} from "vue-property-decorator";
import { BTable, BvTableField} from "bootstrap-vue";
import Vue from 'vue';

@Component({
  components: {ExpandCollapse}
})
export default class SygniTableLegacy<T> extends BTable {

  @Prop({}) items!: Array<T & Record<string, any>>;
  @Prop({}) sortingQueryAction!: string;
  @Prop({}) getItemsAction!: string;
  @Prop({default: 'genprox/setBusyTable'}) setTableBusyMutation!: string;
  @Prop({default: null}) tablePerPageMutation!: string;
  @Prop({}) perPage?: number;
  @Prop({}) busy!: boolean;
  @Prop({default: true}) showPagination!: boolean;
  @Prop() getQuery?: any;
  @Watch('showPagination')onShowPaginationChange(): void {
    (this.paginationInstance.$el as HTMLDivElement).style.display = this.showPagination ? 'visible' : '';
  }

  @Watch('busy') setBusy(): void {
    this.isBusy = this.busy;
  }

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [];

  sortBy: string = '';
  sortDesc: boolean = false;
  sortingQuery: string = '';

  paginationInstance: SygniPagination = {} as SygniPagination;
  currentPage: number = 1;
  itemsLength!: number;
  isBusy: boolean = false;

  @Watch('itemsLength', {immediate: true}) onItemsLengthChange(): void {
    this.paginationInstance.totalRows = this.itemsLength;
  }

  onSortChange(): void {
    setTimeout(() => {
      const sign: string = this.sortDesc ? '-' : '';
      this.sortingQuery = `sort[]=${sign}${this.sortBy}`;
      this.$store.commit(this.sortingQueryAction, {sorting: this.sortingQuery});
      this.$store.dispatch(this.getItemsAction);
    },1);
  }


  onMounted() {
    if(this.tableFields){
      this.setBorderlessTds();
      this.addSortingIcons();
    }
    this.addHoverEffects();
    if(this.showPagination){
      this.addPagination();
    }
  }

  created() {
    this.$root.$on('clearTablesData', () => {
      this.clearTableData()
    })
  }

  clearTableData() {
    if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, 10);
    if (this.sortingQueryAction) this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(1, 10) });
  }

  beforeDestroy() {
    // To implement in component that extends SygniTableLegacy
  }


  @Watch('items')
  setBorderlessTds(): void {
    this.$store.commit(this.setTableBusyMutation, false);
    setTimeout( () => {
      let tds: Array<HTMLTableCellElement> = [];
      this.$el.querySelector('table')!.querySelector('tbody').
      querySelectorAll(':scope > tr:not([class=b-table-details])').forEach( (tr: Element) => {
        tds = tds.concat(Array.from(tr.querySelectorAll('td')));
      });
      let iterator = 0;
      this.tableFields.forEach( (field, index) => {
        if(field.borderless) {
          iterator = 0;
          let borderlessTd = tds[iterator * this.tableFields.length + index];
          while(borderlessTd){
            iterator++;
            borderlessTd.classList.add('borderless');
            borderlessTd = tds[iterator * this.tableFields.length + index];
          }
        }
      });
    }, 10);
  }


  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();
    this.paginationInstance.$mount();
    this.paginationInstance.perPage = 20;
    this.paginationInstance.showPageNumberOptions = true;
    this.paginationInstance.totalRows = this.itemsLength;
    
    if (this.getQuery) {
      this.$nextTick(() => {
        const query = this.$store.getters[this.getQuery]
        const params = new URLSearchParams(query?.limit)
        const offset = Number(params.get('offset')) || 0
        const limit = Number(params.get('limit')) || this.perPage || 10
        
        const currentPage = (offset / limit) + 1
        this.paginationInstance.currentPage = currentPage
      })
    }

    this.paginationInstance.$on('change', (currentPage: number) => {
      this.currentPage = currentPage;
      this.$store.commit(this.setTableBusyMutation, true);
      this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(currentPage, 10)});
      this.$store.dispatch(this.getItemsAction);
    })
    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;
    this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
  }

  createLimitQuery(currentPage: number, perPage: number): string {
    const limit = perPage;
    const offset = perPage * (currentPage - 1);
    return `limit=${limit}&offset=${offset}`;
  }

  addHoverEffects():void{
    this.$el.querySelectorAll('tr').forEach((el) => {
      el.addEventListener('mouseenter', (event) => {
        if(el !== event.target) return;
        this.onRowEnter(el);
      });
    });
    this.$el.querySelectorAll('tr').forEach((el) => {
      el.addEventListener('mouseleave', (event) => {
        if(el !== event.target) return;
        this.onRowLeave(el);
      });
    });
  }

  addSortingIcons(): void {
    const ths = this.$el.querySelectorAll('th');
    const iconsHTML: string = `<div class="th__arrows">
        <img class="arrow-up" src="${require('@/assets/icons/arrow.svg')}">
        <img class="arrow-down" src="${require('@/assets/icons/arrow.svg')}"></div>`

    this.tableFields.forEach( (field: any, index: number) => {
      if(field.sortable && ths[index]) {
        (ths[index].firstChild as HTMLElement).innerHTML += iconsHTML;
      }
    });
  }

  onRowEnter(rowElement: HTMLTableRowElement){
    rowElement.classList.add('hover');
  }
  onRowLeave(rowElement: HTMLTableRowElement){
    rowElement.classList.remove('hover');
  }
}

